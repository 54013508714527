import React, { FC } from 'react'

interface IXIconLinkProps {
  src: any
  className?: string
}

const XIconLink: FC<IXIconLinkProps> = ({ src, className }) => (
  <a href="https://x.com/TheIFAB/" target="_blank" rel="noreferrer">
    <img src={src} alt="X icon" className={className} />
  </a>
)

export default XIconLink
