import React, { FC } from 'react'
import XBoxIcon from 'src/components/icons/XBox.svg'
import FacebookBoxIcon from 'src/components/icons/FacebookBox.svg'
import LinkedinIcon from 'src/components/icons/Linkedin.png'
import FacebookIconLink from 'src/components/icons/FacebookIconLink'
import XIconLink from 'src/components/icons/XIconLink'
import LinkedinIconLink from 'src/components/icons/LinkedinIconLink'

interface IJoinUsIconsMobileProps {
  className?: string
}

const JoinUsIconsMobile: FC<IJoinUsIconsMobileProps> = ({ className }) => (
  <div className={`flex justify-center ${className || ''}`}>
    <XIconLink src={XBoxIcon} className="w-9" />
    <FacebookIconLink src={FacebookBoxIcon} className="w-9 ml-4" />
    <LinkedinIconLink
      src={LinkedinIcon}
      className="ml-4"
      style={{ width: '41px' }}
    />
  </div>
)

export default JoinUsIconsMobile
